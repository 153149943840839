@if (paginatorForm) {
  <div class="pagination row g-0" [ngClass]="{ 'pagination--hidden': loading }" [formGroup]="paginatorForm">
    <div class="col-12 col-md-6">
      <div class="pagination__per-page">
        <div class="pagination__showing">
          {{ 'table.showing' | translate }}
        </div>
        <div class="pagination__changer">
          <cap-dropdown
            formControlName="perPage"
            sortBy="value"
            [sortAlphabetically]="false"
            [options]="perPageOptions"
            (change)="changePerPage?.emit($event.value)">
          </cap-dropdown>
        </div>
        <div class="pagination__entries">
          {{ 'table.paginator.of' | translate }} {{ paginator?.total }} {{ 'table.entries' | translate }}
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 text-right">
      <p-paginator
        [rows]="paginator?.per_page"
        [totalRecords]="paginator?.total"
        (onPageChange)="customChangePage($event.page)">
      </p-paginator>
    </div>
  </div>
}

<div class="pagination--skeleton" [ngClass]="{ 'pagination--visible': loading }">
  <div class="row g-0">
    <div class="col pagination__per-page">
      <cap-skeleton width="58px" height="18px"></cap-skeleton>
      <cap-skeleton width="90px" height="32px"></cap-skeleton>
      <cap-skeleton width="87px" height="18px"></cap-skeleton>
    </div>

    <div class="col text-right">
      <cap-skeleton width="240px" height="32px"></cap-skeleton>
    </div>
  </div>
</div>
