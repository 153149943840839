import { Paginator } from 'primeng/paginator';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Meta } from '@capturum/api';
import { UntypedFormGroup } from '@angular/forms';
import { MapItem } from '@capturum/ui/api';

@Component({
  selector: 'app-info-table-paginator',
  templateUrl: './info-table-paginator.component.html',
  styleUrls: ['./info-table-paginator.component.scss'],
})
export class InfoTablePaginatorComponent implements OnInit, AfterViewInit {
  public firstLoad = false;

  @Input() public paginator: Meta['pagination'];
  @Input() public paginatorForm: UntypedFormGroup;
  @Input() public stateKey: string;
  @Input() public perPageOptions: MapItem[];
  @Input() public loading: boolean;
  @Output() public changePage: EventEmitter<number> = new EventEmitter<number>();
  @Output() public changePerPage: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild(Paginator) public pPaginator: Paginator;

  constructor() {
    this.perPageOptions = [10, 20, 50, 100].map((amount) => {
      return {
        label: amount.toString(),
        value: amount,
      };
    });
  }

  public ngOnInit(): void {
    const currentPaginatorState = JSON.parse(localStorage.getItem(this.stateKey));

    if (currentPaginatorState) {
      this.paginator = currentPaginatorState?.paginator;
    }
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.pPaginator?.changePage(this.paginator?.current_page - 1);
    });
  }

  public customChangePage(page: number): void {
    if (!this.firstLoad) {
      this.firstLoad = true;

      return;
    }

    this.changePage.emit(page);
  }
}
